import {PriceModel} from './Price.model';
import {PickupInfoModel} from './PickupInfo.model';
import {ScheduledDeliveryOptionModel} from './ScheduledDeliveryOption.model';
import {ShippingOptionFragment} from '../../gql/graphql';
import {convertToDateTimeByTimezone, dateKeyFromDateTime, dateTimeToTimeString} from '../utils/dateTime.utils';

export class ShippingOptionModel {
  public code: string;
  public title: string;
  public pickupInfo?: PickupInfoModel;
  public deliveryTime: string;
  public price: PriceModel;
  public hasTimeSlots?: boolean;
  public scheduledDeliveryOptions?: ScheduledDeliveryOptionModel[];
  public timeSlotDays?: {[date: string]: TimeSlotOption[]};
  public hasDifferentPricedTimeSlots?: boolean;

  constructor(
    shippingOption: ShippingOptionFragment,
    aggregatedDeliveryOptions?: ShippingOptionFragment[],
    options?: {shouldSupportTimeSlotsInCheckout?: boolean; timezone?: string}
  ) {
    this.code = shippingOption.code!;
    this.title = shippingOption.title!;
    this.pickupInfo = shippingOption?.logistics?.pickupDetails
      ? new PickupInfoModel(shippingOption?.logistics)
      : undefined;
    this.deliveryTime = shippingOption.logistics?.deliveryTime ?? '';
    this.price = new PriceModel(shippingOption.cost?.price);
    this.hasTimeSlots =
      options?.shouldSupportTimeSlotsInCheckout &&
      Boolean(shippingOption?.logistics?.deliveryTimeSlot) &&
      Boolean(aggregatedDeliveryOptions);
    if (this.hasTimeSlots) {
      this.timeSlotDays = getTimeSlotDays(aggregatedDeliveryOptions!, options?.timezone);
      this.hasDifferentPricedTimeSlots = Boolean(
        Object.keys(this.timeSlotDays)
          .flatMap((day) => this.timeSlotDays?.[day])
          .find((option) => option?.price.amount !== this.price.amount)
      );
    } else {
      this.scheduledDeliveryOptions = aggregatedDeliveryOptions?.map(
        (option) => new ScheduledDeliveryOptionModel(option)
      );
    }
  }
}

function getTimeSlotDays(aggregatedDeliveryOptions: ShippingOptionFragment[], timezone?: string) {
  const timeSlotOptions = aggregatedDeliveryOptions
    .filter((option) => !!option.logistics?.deliveryTimeSlot?.from)
    .map((option) => new TimeSlotOption(option, timezone));

  return timeSlotOptions.reduce<{[dateString: string]: TimeSlotOption[]}>((acc, option) => {
    acc[option.dateKey] = acc[option.dateKey] || [];
    acc[option.dateKey].push(option);
    return acc;
  }, {});
}

export class TimeSlotOption {
  public id: string;
  public dateAtStartOfDay: Date;
  public price: PriceModel;
  public dateMillis: number;
  public dateKey: string;
  public timeString: string;

  constructor(option: ShippingOptionFragment, timezone?: string) {
    this.id = option.code!;
    this.dateMillis = +option.logistics!.deliveryTimeSlot!.from!;
    const dateTime = convertToDateTimeByTimezone(this.dateMillis, timezone);
    this.dateAtStartOfDay = new Date(dateTime.year, dateTime.month - 1, dateTime.day);
    this.dateKey = dateKeyFromDateTime(dateTime);
    this.price = new PriceModel(option.cost?.price);
    this.timeString = dateTimeToTimeString(dateTime);
  }
}
